<template>
<div>
  
  <section class="slice slice-lg bg-primary" style="background-image:url('/img/women.jpg');background-size:cover;">
    <span class="mask bg-gradient-dark opacity-6"></span>
    <div class="container d-flex align-items-center pt-lg-6">
        <div class="col px-0"><div class="row row-grid align-items-center">
            <div class="col-lg-6 text-lg-left">
                <h1 class="display-4 text-center text-lg-left text-white my-4">Easy Issue Life Insurance for Diabetics</h1>
                <ul class="list-unstyled list-bullet ml-4">
                    <li class="py-2 text-white">
                        <span class="badge badge-circle badge-soft-success mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </span> 
                        <span class="h5 mb-0 ml-2 text-white">No Exams</span> 
                    </li>
                    <li class="py-2 text-white">
                        <span class="badge badge-circle badge-soft-success mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </span> 
                        <span class="h5 mb-0 ml-2 text-white">Easy Acceptance</span>
                    </li>
                    <li class="py-2 text-white">
                        <span class="badge badge-circle badge-soft-success mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </span> 
                        <span class="h5 mb-0 ml-2 text-white">Benefits for Long Term Care, Heart Attack, Stroke and Cancer included</span>
                    </li>
                    <li class="py-2 text-white">
                        <span class="badge badge-circle badge-soft-success mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </span> 
                        <span class="h5 mb-0 ml-2 text-white">Coverage from $50,000 to $400,000</span>
                    </li>
                    
                    <li class="py-2 text-white">
                        <span class="badge badge-circle badge-soft-success mr-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline>
                            </svg>
                        </span> 
                        <span class="h5 mb-0 ml-2 text-white">Apply Online, No agent visit necessary</span>
                    </li>
                </ul>
                <div class="mt-5">
                    <!-- <button @click="$router.push({name: 'HowToApply'})" class="btn btn-white btn-lg btn-icon">
                        <span class="btn-inner--text">What's Next </span><span class="btn-inner--icon"> <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                        </span>
                    </button> -->
                </div>
                <div class="d-flex align-items-center justify-content-center justify-content-lg-left mt-5">
                    
                </div>
            </div>
            <div class="col-lg-6 col-xl-5 col-sm-8 ml-auto mr-auto mr-lg-0">
                <forest-quoter></forest-quoter>
            </div>
        </div>
    </div>
</div>
<div class="shape-container shape-position-bottom shape-line">
    <svg width="2560px" height="100px" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" viewBox="0 0 2560 100" style="enable-background:new 0 0 2560 100" xml:space="preserve" class=""><polygon points="2560 0 2560 100 0 100"></polygon></svg>
</div>
  </section>
  
<!--   <section class="slice slice-lg pb-0">
      <div class="container">
          <div class="mb-5 text-center">
             
              <h3>Attention Diabetics</h3>
              <div class="fluid-paragraph mt-3">
                  <p class="lead lh-180">If you qualify, you can not be turned down</p>
              </div>
            </div>
       
    </div>
</section> -->
  
  <section class="slice">
      <div class="container">
          <div class="section-process-ste">
              <div class="row row-grid justify-content-between align-items-center">
                  <div class="col-lg-5 order-lg-2">
                      <h5 class="h3">Acceptance for Diabetics is Now easier than ever</h5>
                      <p class="lead my-4">If you are type 1 or type 2 and of average health or better Acceptance is easy. Simply be between the ages of 18 to 80 and you do not need to take an exam, this Life Policy is for you.</p>
                      <ul class="list-unstyled mb-0">
                          <li class="py-2">
                              <div class="d-flex align-items-center">
                                  <div>
                                      <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3">
                                          <i class="fas fa-file-invoice"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <span class="h6 mb-0">Type 1 & 2 Diabetics</span>
                                    </div>
                                </div>
                            </li>
                            <li class="py-2">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3">
                                            <i class="fas fa-store-alt"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <span class="h6 mb-0">Ages of 18 to 80</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-6 order-lg-1">
                        <div class="card mb-0 mr-lg-5">
                            <div class="card-body p-2">
                                <img alt="Image placeholder" src="/img/diabetic.jpg" class="img-fluid shadow rounded">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-process-ste">
                <div class="row row-grid justify-content-between align-items-center">
                    <div class="col-lg-5">
                        <h5 class="h3">No Medical Exams</h5>
                        <p class="lead my-4">There are no medical exams EVER, or lengthy health questionnaires required to qualify for coverage. No need to waste time waiting for doctor records.</p>
                        <ul class="list-unstyled mb-0">
                          <li class="py-2">
                              <div class="d-flex align-items-center">
                                  <div>
                                      <div class="icon icon-shape bg-primary text-white icon-sm rounded-circle mr-3">
                                          <i class="fas fa-file-invoice"></i>
                                        </div>
                                    </div>
                                    <div>
                                        <span class="h6 mb-0"><strong>100%</strong> Online Process</span>
                                    </div>
                                </div>
                            </li>
                           
                        </ul>
                    </div>
                    <div class="col-lg-6">
                        <div class="card mb-0 ml-lg-5">
                            <div class="card-body p-2">
                                <img alt="Image placeholder" src="https://images.unsplash.com/photo-1593491205049-7f032d28cf5c?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80" class="img-fluid shadow rounded">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </section>
  
 
  <section class="slice slice-lg">
    <div class="container-fluid" style="margin-top: -105px;">
        <div class="row justify-content-center">
            <div class="col-lg-8">
                
                
                <h1 class="d-inline-block mt-7 mb-5 text-highlight-warning text-center">Easy Issue Term Life Insurance</h1>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="card py-4 shadow-lg hover-translate-y-n10 hover-shadow-lg">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <div class="icon icon-sm bg-warning rounded-circle icon-shape">
                                            <span class="h6 mb-0">1</span>
                                        </div>
                                    </div>
                                    <div class="pl-3">
                                        <h5 class="h4 mb-0">Guaranteed Rates</h5>
                                    </div>
                                </div>
                                <p class="mt-4 mb-0">Premiums are fixed for the full term of the policy. You have a choice that fits your budget.  Choose 10,15,20,25 or 30 year Guaranteed Level Term. Any changes in your health in the future will not affect your rates
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="card py-4 shadow-lg hover-translate-y-n10 hover-shadow-lg">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <div class="icon icon-sm bg-warning rounded-circle icon-shape">
                                            <span class="h6 mb-0">2</span>
                                        </div>
                                    </div>
                                    <div class="pl-3">
                                        <h5 class="h4 mb-0">No Medical Exams</h5>
                                    </div>
                                </div>
                                <p class="mt-4 mb-0">There are no medical exams EVER, or lengthy health questionnaires required to qualify for coverage. No need to waste time waiting for doctor records.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 col-sm-12">
                        <div class="card py-4 shadow-lg hover-translate-y-n10 hover-shadow-lg">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <div class="icon icon-sm bg-warning rounded-circle icon-shape">
                                            <span class="h6 mb-0">3</span>
                                        </div>
                                    </div>
                                    <div class="pl-3">
                                        <h5 class="h4 mb-0">Living Benefits</h5>
                                    </div>
                                </div>
                                <p class="mt-4 mb-0">
                                    At no additional cost to you, <strong>living Benefits</strong> adds additional coverage not found in a traditional Life Insurance Policy.  
                                    In the event of a <strong>critical illness</strong>, you can access a pool of money from your Life Insurance Policy at no additional cost to you. 
                                </p>
                                <div class="mt-2 text-center">
                                    <span href="#" class="link-underline-primary" @click="showLivingBenefits()" style="cursor:pointer;">Learn More</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <div class="card py-4 shadow-lg hover-translate-y-n10 hover-shadow-lg">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div>
                                        <div class="icon icon-sm bg-warning rounded-circle icon-shape">
                                            <span class="h6 mb-0">4</span>
                                        </div>
                                    </div>
                                    <div class="pl-3">
                                        <h5 class="h4 mb-0">Law Assure</h5>
                                    </div>
                                </div>
                                <p class="mt-4 mb-0">
                                    Another Benefit included with your life policy is <strong>Law Assure</strong>.  
                                    A <strong>free benefit</strong> for being a policyholder, it saves you money by not having to pay an attorney to draw legal documents: create a will, healthcare directive or power of attorney.
                                    

                                </p>
                                <div class="mt-2 text-center">
                                    <span href="#" class="link-underline-primary" @click="showLawAssure()" style="cursor:pointer;">Learn More</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </section>
  <section class="slice slice-lg">
    <div class=container>
        <div class="row mb-5 justify-content-center text-center">
            <div class="col-lg-8 col-md-10">
                <h2 class=mt-4>Need personal assistance?</h2>
                <div class=mt-2>
                    <p class="lead lh-180">If you still didn't find an answer you can always contact us</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-5" ><div class=card>
                <div class="card-body p-5 text-center">
                    <div class=pb-4>
                        <img alt="Image placeholder" src=https://preview.webpixels.io/quick-website-ui-kit/assets/img/svg/icons/Call_Out.svg class="svg-inject img-fluid" style=height:40px>
                    </div>
                    <h5>(800) 256-5307</h5>
                    <p class="mt-2 mb-0">Our support team is here for you.</p>
                </div>
            </div>
        </div>
        <div class=col-md-5>
            <div class=card>
                <div class="card-body p-5 text-center">
                    <div class=pb-4>
                        <img alt="Image placeholder" src=https://preview.webpixels.io/quick-website-ui-kit/assets/img/svg/icons/Email.svg class="svg-inject img-fluid" style=height:40px>
                    </div>
                    <h5 style="font-size:1.15rem">
                        <router-link :to="{name: 'Contact'}">Questions@<br>visionaryinsurance.life
                        </router-link></h5>
                    <p class="mt-2 mb-0">You'll get a reply in 4 to 12 hours.</p>
                </div>
            </div>
        </div>
    </div>
</div>
  </section>
  <law-assure 
    ref="lawAssure"
    />
  <living-benefits 
    ref="livingBenefits"
    />
</div>
</template>

<script>
import moment from 'moment';
import flatpickr from "flatpickr";
import axios from 'axios';
import forestQuoter from './form/Quoter.vue'
import headerDark from './comps/HeaderDark.vue'
import lawAssure from './comps/LawAssure.vue'
import livingBenefits from './comps/LivingBenefits.vue'
export default {
  name: 'Home',
  components: {
    forestQuoter, headerDark, lawAssure, livingBenefits
  },
  data() {
      return {
          loading: false,
          loadingEdit: false,
          todayYear: new Date().getFullYear() - 18,
          quoteSubmitted: false,
          policy: "",
          form: {
              amount: '',
              state: '',
              ss: '',
              gender: '',
              dob: '',
              age: '',
              term: 20,
          },
          selectedDay: '',
          selectedMonth: '',
          selectedYear: '',
          tempShowCard: false,
          coverageValue: 75,
          coverageTerm: 15,
          minCoverageRange: 50,
          maxCoverageRange: 400,
          minTermRange: 10,
          maxTermRange: 30,
      }
  },
  mounted() {
      console.log('Mounted');
      if(this.$store.state.dPolicy.year) {
          this.form = this.$store.state.dPolicy;
      }
      
    flatpickr('#datepicker', {
        dateFormat: "m/d/Y",
        maxDate: "07/28/2003",
    });
  },
  methods: {
      submitQuote() {
          console.log('submitting quote - ' + this.form.term);
          console.log(this.form);
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedDay != '' && this.selectedMonth != '' && this.selectedYear != '') {
              this.loading = true;
              
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
                this.coverageTerm = this.form.term;
              this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              console.log("Form term - " + this.form.term);
            this.form.age = moment(this.form.dob, "MM/DD/YYYY").fromNow().split(" ")[0];
            console.log("Age: " + this.form.age);
            if(this.form.gender == "Male" && this.form.ss == "ns") {
                console.log("inside male ns")
                if(this.form.term == 10 && (this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 22');
                    this.minTermRange = 15;
                } 
                if((this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 22');
                    this.minTermRange = 15;
                }
                if(this.form.term == 20 && this.form.age >= 71) {
                    console.log('client is older than 65 for 20 year policy 10');
                    this.form.term = 10;
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 71) {
                    console.log('client is older than 71'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 for 20 year policy 15');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 within index ' + this.form.term); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55 for 25 year policy');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50 for 20 year policy');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50'); 
                    this.maxTermRange = 25;
                }
            } else if(this.form.gender == "Male" && this.form.ss == "ss") {
                console.log("inside male ss");
                if(this.form.term == 20 && this.form.age >= 61) {
                    console.log('client is older than 61 for 20 year policy 10');
                    this.form.term = 15;
                    this.maxTermRange = 15;
                    this.reSubmit();
                }
                if(this.form.age > 71) {
                    console.log('client is older than 71 ss'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss'); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 50 && this.form.age < 60) {
                    console.log('client is older than 55 ss');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 50 && this.form.age < 60) {
                    console.log('client is older than 50'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 45 && this.form.age < 50) {
                    console.log('client is older than 45');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.form.age > 45 && this.form.age < 50) {
                    console.log('client is older than 45'); 
                    this.maxTermRange = 25;
                }
            } else if(this.form.gender == "Female" && this.form.ss == "ns") {
                console.log("inside female ns");
                if(this.form.term == 10 && (this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22 || this.form.age == 23)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                    this.form.term = 15;
                    
                    this.reSubmit();
                } 
                if((this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22 || this.form.age == 23)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                }
                if(this.form.term >= 15 && this.form.age >= 71) {
                    console.log('client is older than 72');
                    this.form.term = 10;
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 71) {
                    console.log('client is older than 71'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 for 20 year policy 15');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 within index ' + this.form.term); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55 for 25 year policy');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50 for 20 year policy');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50'); 
                    this.maxTermRange = 25;
                }
            } else if(this.form.gender == "Female" && this.form.ss == "ss") {
                console.log("inside female ss");
                if(this.form.term == 10 && (this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                    this.form.term = 15;
                    
                    this.reSubmit();
                } 
                if((this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                }
                
                if(this.form.age > 71) {
                    console.log('client is older than 71 ss'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss'); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 55 && this.form.age < 60) {
                    console.log('client is older than 55 ss');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 55 && this.form.age < 60) {
                    console.log('client is older than 55'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 55');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 45'); 
                    this.maxTermRange = 25;
                }
            }

            
            

              axios.post("/api/fetchforestquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = resp.data.quote;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                policy.term = this.form.term;
                this.coverageValue = this.form.amount;
                
                    // do some logic for age limitations
                    

                    

                    

                   


                    

                
                // add to local policy for home quoter
                console.log(this.form);
                console.log("beofre form next dPolicy");
                console.log(this.$store.state.dPolicy);
                this.$store.state.dPolicy = policy;
                this.$store.state.dPolicy.month = policy.dob.split('/')[0];
                this.$store.state.dPolicy.day = policy.dob.split('/')[1];
                this.$store.state.dPolicy.year = policy.dob.split('/')[2];
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.quoteSubmitted = true;
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.selectedDay == '') {
              this.$toasted.error('Select Day');
          } else if(this.selectedMonth == '') {
              this.$toasted.error('Select Month');
          } else if(this.selectedYear == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      submitQuoteAlt() {
          console.log('submitting alt - ' + this.form.term);
          console.log(this.form);
          
          let policy = {};
         
              this.loading = true;
              
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
                this.coverageTerm = this.form.term;
              this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              console.log("Form term - " + this.form.term);
              axios.post("/api/fetchaltforestquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = resp.data.quote;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                policy.term = this.form.term;
                this.coverageValue = this.form.amount;
                
                    

                    



                    

                
                // add to local policy for home quoter
                this.$store.state.dPolicy = policy;
                this.$store.state.dPolicy.month = policy.dob.split('/')[0];
                this.$store.state.dPolicy.day = policy.dob.split('/')[1];
                this.$store.state.dPolicy.year = policy.dob.split('/')[2];
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.quoteSubmitted = true;
            });
          
      },
      submitQuote2() {
          console.log('submitting quote - dpolicy below');
          console.log(this.$store.state.dPolicy);
          this.form = this.$store.state.dPolicy;
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.form.day != '' && this.form.month != '' && this.form.year != '') {
              this.loading = true;
              
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
                this.coverageTerm = this.form.term;
              this.form.dob = this.$store.state.dPolicy.month + '/' + this.$store.state.dPolicy.day + '/' + this.$store.state.dPolicy.year;
              console.log("Form term - " + this.form.term);
            this.form.age = moment(this.form.dob, "MM/DD/YYYY").fromNow().split(" ")[0];
            console.log("Age: " + this.form.age);
            if(this.form.gender == "Male" && this.form.ss == "ns") {
                console.log("inside male ns")
                if(this.form.term == 10 && (this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 22');
                    this.minTermRange = 15;
                } 
                if((this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 22');
                    this.minTermRange = 15;
                }
                if(this.form.term == 20 && this.form.age >= 71) {
                    console.log('client is older than 65 for 20 year policy 10');
                    this.form.term = 10;
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 71) {
                    console.log('client is older than 71'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 for 20 year policy 15');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 within index ' + this.form.term); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55 for 25 year policy');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50 for 20 year policy');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reAltSubmit();
                }
                if(this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50'); 
                    this.maxTermRange = 25;
                }
            } else if(this.form.gender == "Male" && this.form.ss == "ss") {
                console.log("inside male ss");
                if(this.form.term == 20 && this.form.age >= 61) {
                    console.log('client is older than 61 for 20 year policy 10');
                    this.form.term = 15;
                    this.maxTermRange = 15;
                    this.reAltSubmit();
                }
                if(this.form.age > 71) {
                    console.log('client is older than 71 ss'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss'); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 50 && this.form.age < 60) {
                    console.log('client is older than 55 ss');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 50 && this.form.age < 60) {
                    console.log('client is older than 50'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 45 && this.form.age < 50) {
                    console.log('client is older than 45');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reAltSubmit();
                }
                if(this.form.age > 45 && this.form.age < 50) {
                    console.log('client is older than 45'); 
                    this.maxTermRange = 25;
                }
            } else if(this.form.gender == "Female" && this.form.ss == "ns") {
                console.log("inside female ns");
                if(this.form.term == 10 && (this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22 || this.form.age == 23)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                    this.form.term = 15;
                    
                    this.reAltSubmit();
                } 
                if((this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22 || this.form.age == 23)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                }
                if(this.form.term >= 15 && this.form.age >= 71) {
                    console.log('client is older than 72');
                    this.form.term = 10;
                    this.maxTermRange = 10;
                    this.reAltSubmit();
                }
                if(this.form.age > 71) {
                    console.log('client is older than 71'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 for 20 year policy 15');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 within index ' + this.form.term); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55 for 25 year policy');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50 for 20 year policy');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reAltSubmit();
                }
                if(this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50'); 
                    this.maxTermRange = 25;
                }
            } else if(this.form.gender == "Female" && this.form.ss == "ss") {
                console.log("inside female ss");
                if(this.form.term == 10 && (this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                    this.form.term = 15;
                    
                    this.reAltSubmit();
                } 
                if((this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                }
                
                if(this.form.age > 71) {
                    console.log('client is older than 71 ss'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss'); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 55 && this.form.age < 60) {
                    console.log('client is older than 55 ss');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 55 && this.form.age < 60) {
                    console.log('client is older than 55'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 55');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reAltSubmit();
                }
                if(this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 45'); 
                    this.maxTermRange = 25;
                }
            }

            
            

              axios.post("/api/fetchforestquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = resp.data.quote;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                policy.term = this.form.term;
                this.coverageValue = this.form.amount;
                
        
                this.$store.state.dPolicy = policy;
                this.$store.state.dPolicy.month = policy.dob.split('/')[0];
                this.$store.state.dPolicy.day = policy.dob.split('/')[1];
                this.$store.state.dPolicy.year = policy.dob.split('/')[2];
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.quoteSubmitted = true;
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.form.day == '') {
              this.$toasted.error('Select Day');
          } else if(this.form.month == '') {
              this.$toasted.error('Select Month');
          } else if(this.form.year == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      submitQuoteAlt2() {
          console.log('submitting alt 2 - ' + this.form.term);
          console.log(this.form);

          this.form = this.$store.state.dPolicy;
          
          let policy = {};
         
              this.loading = true;
              
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
                this.coverageTerm = this.form.term;
              this.form.dob = this.$store.state.dPolicy.month + '/' + this.$store.state.dPolicy.day + '/' + this.$store.state.dPolicy.year;
              console.log("Form term - " + this.form.term);
              axios.post("/api/fetchaltforestquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = resp.data.quote;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                
                policy.rstartdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                policy.term = this.form.term;
                this.coverageValue = this.form.amount;
                
                    

                    



                    

                
                // add to local policy for home quoter
                this.$store.state.dPolicy = policy;
                this.$store.state.dPolicy.month = policy.dob.split('/')[0];
                this.$store.state.dPolicy.day = policy.dob.split('/')[1];
                this.$store.state.dPolicy.year = policy.dob.split('/')[2];
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.quoteSubmitted = true;
            });
          
      },
      submitQuote2Old() {
          console.log('submitting quote');
          console.log(this.form);
          this.form = this.$store.state.dPolicy;
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.form.day != '' && this.form.month != '' && this.form.year != '') {
              this.loading = true;
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
              this.form.dob = this.form.month + '/' + this.form.day + '/' + this.form.year;
              axios.post("/api/fetchforestquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = parseInt(resp.data.quote) + 400;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                this.coverageValue = this.form.amount;
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;


                this.$store.state.dPolicy = policy;
                this.$store.state.dPolicy.month = policy.dob.split('/')[0];
                this.$store.state.dPolicy.day = policy.dob.split('/')[1];
                this.$store.state.dPolicy.year = policy.dob.split('/')[2];
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.quoteSubmitted = true;
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.form.day == '') {
              this.$toasted.error('Select Day');
          } else if(this.form.month == '') {
              this.$toasted.error('Select Month');
          } else if(this.form.year == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      reSubmit() {
          
          console.log("resubmitting ");
          this.submitQuoteAlt();
      },
      reAltSubmit() {
          
          console.log("resubmitting ");
          this.submitQuoteAlt2();
      },
      formatCurrency(amount) {
            // amount = (amount / 100);
            return amount.toLocaleString('en', { currency: 'usd', minimumFractionDigits: 2 });
        },
        formatCurrency2(amount) {
            amount = (amount);
            return amount.toLocaleString('en', { style: 'currency', currency: 'usd', minimumFractionDigits: 0 });
        },
      showValue(event) {
          console.log(event.target.value);
          this.coverageValue = event.target.value;
      },
      showTerm(event) {
          console.log(event.target.value);
          this.coverageTerm = event.target.value;
      },
      showValueChange(event){
          this.form = this.$store.state.dPolicy;
          this.form.amount = this.coverageValue;
          this.form.term = this.coverageTerm;
          this.form.dob = this.form.month + '/' + this.form.day + '/' + this.form.year;
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.form.day != '' && this.form.month != '' && this.form.year != '') {
              this.loadingEdit = true;
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
                
              //this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              axios.post("/api/fetchforestquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = resp.data.quote;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                this.coverageValue = this.form.amount;
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                policy.term = this.form.term;
                this.$store.state.dPolicy = policy;
                this.$store.state.dPolicy.month = policy.dob.split('/')[0];
                this.$store.state.dPolicy.day = policy.dob.split('/')[1];
                this.$store.state.dPolicy.year = policy.dob.split('/')[2];
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loadingEdit = false;
                this.quoteSubmitted = true;
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.form.day == '') {
              this.$toasted.error('Select Day');
          } else if(this.form.month == '') {
              this.$toasted.error('Select Month');
          } else if(this.form.year == '') {
              this.$toasted.error('Select Year');
          }
      },
      editValue(){
          this.quoteSubmitted = false;
      },
      amountChange(event) {
          if(event.target.value !== "Amount") {
              this.form.amount = event.target.value;
              console.log('succesfully changed');
          } else {
              console.log("please select from the dropdown");
          }
      },
      stateChange(event) {
          if(event.target.value !== "State") {
              this.form.state = event.target.value;
              console.log('succesfully changed states');
          } else {
              console.log("please select from the dropdown");
          }
      },
      ssChange(event) {
          if(event.target.value !== "Smoking Status") {
              this.form.ss = event.target.value;
              console.log('succesfully selected status');
          } else {
              console.log("please select from the dropdown");
          }
      },
      genderChange(event) {
          if(event.target.value !== "Gender") {
              this.form.gender = event.target.value;
              console.log('succesfully selected gender');
          } else {
              console.log("please select from the dropdown");
          }
      },
      changeDay(event) {
          if(event.target.value !== 'Day') {
              this.selectedDay = event.target.value;
              console.log(event.target.value);
          }
      },
      changeMonth(event) {
          if(event.target.value !== 'Month') {
              this.selectedMonth = event.target.value;
              console.log(event.target.value);
          }
      },
      changeYear(event) {
          if(event.target.value !== 'Year') {
              this.selectedYear = event.target.value;
              console.log(event.target.value);
          }
      },
      showLawAssure() {
          $(this.$refs.lawAssure.$el).modal('show');
      },
      showLivingBenefits() {
          $(this.$refs.livingBenefits.$el).modal('show');
      },
      closeLawAssure() {
          $(this.$refs.lawAssure.$el).modal('hide');
      },
      closeLivingBenefits() {
          $(this.$refs.livingBenefits.$el).modal('hide');
      }
  },
}
</script>
<style scoped>
  .form-control2 {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: .75rem 1.25rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4a5568;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e2e8f0;
    border-radius: .375rem;
    box-shadow: inset 0 1px 1px rgb(31 45 61 / 8%);
    transition: all .2s ease;
  }
  #datepicker::-webkit-input-placeholder {
    padding-left:12px;
  }
  #datepicker::-ms-input-placeholder {
    padding-left: 12px;
  }
  #datepicker::placeholder {
    padding-left:12px;
  }

  #datepicker::value {
    color:red;
  }

    .section-process-ste {
        position: relative;
        padding: 4.5rem 0;
    }

</style>
