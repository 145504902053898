<template>
    <div class="modal fade docs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Visionary Inc</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mt-4 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-md-8" style="display: flex; justify-content: center;">
                    <div class="row" style="justify-content: center;">
                        
                        
                        <div class="col-md-12">
                            <div class="card mt-2">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-12">
                                            <div class="media">
                                               
                                                <div class="media-body">
                                                    <p>
                                                    <h5 class="">Law Assure</h5>
                                                    </p>
                                                    <p class="mb-0">
                                                        Law Assure is one of our new complimentary member benefits. This online document preparation service makes it easier to help take care of important matters from the comfort of your own home including:
                                                        <ul>
                                                            <li>Writing a will </li>
                                                            <li>Making a power of attorney</li>
                                                            <li>Preparing a healthcare directive </li>
                                                        </ul>
                                                    </p>
                                                    <p>
                                                        LawAssure uses pre-programmed logic and your answers to a series of questions to instantly customize and populate important documents. <br>
                                                        <strong>Securely store and share important documents </strong>
                                                    </p>
                                                    <p>LawAssure provides access to a secure online platform where you can store and print your documents. It’s mobile friendly, so you can easily access your documents while on-the-go. You can also use the service to securely share documents with anyone, such as a family member, a notary, or a trusted advisor</p>
                                                        
                                                   
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            
                            <button @click="close()" type="submit" class="btn btn-primary btn-loading">
                                
                                <span>Close</span>
                                
                            </button>
                        </div>
                      
                        
                       
                        
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>

export default {
    name: "law-assure",
   
    
    data() {
        return {
            slot: '',
            loading: false,
            form: {
                name: '',
            },
        }
    },
    methods: {
        close() {
            //$(this.$parent.$refs.llp.$el).modal('hide');
            this.$parent.closeLawAssure();
        }
    }
   
   
    
}
</script>