<template>
    <div class="modal bd-example-modal-lg fade docs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">

        <div class="modal-header">
            <h5 class="modal-title h6" id="myLargeModalLabel">Visionary Inc</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row mt-4 pb-4" style="display: flex; justify-content: center; margin-top: -24px;">
                <div class="col-md-8" style="display: flex; justify-content: center;">
                    <div class="row" style="justify-content: center;">
                        
                        
                        <div class="col-md-12">
                            <div class="card mt-2">
                                <div class="card-body">
                                    <div class="row row-grid align-items-center">
                                        <div class="col-lg-12">
                                            <div class="medi">
                                               
                                                <div class="media-body">
                                                    <p>
                                                    <h5 class="">Living Beneftis</h5></p>
                                                    <p class="mb-0">
                                                       Chronic Illness • Certified, by a licensed health care practitioner, as being unable to perform at least two of the six activities of daily living for at least 90 days OR with severe cognitive impairment. The chronic illness must be diagnosed as requiring permanent continuous care, in an eligible facility or at home, with a plan of care in place. 
                                                        <br>For any 12 month benefit period, maximum acceleration is: 
                                                        <ul>
                                                            <li>Lesser of 24% of the eligible death benefit at the time of the initial chronic illness claim or;</li>
                                                            <li>An amount totaling all accelerated death benefit payments received during that benefit period that equals the per diem limitation under section 7702B(d) of the IRC. Only one payment can be made during a benefit period. Claim amount is subject to an overall lifetime maximum of 95% of the eligible death benefit, up to $500,000.</li>
                                                        </ul>
                                                    </p>
                                                    <p>Critical Illness • Certified by a physician as having life threatening cancer, myocardial infarction (heart attack), stroke, end stage renal failure, or major organ failure. •	 Can accelerate up to 95% of the eligible death benefit at the time of each critical illness claim to a maximum of $500,000.</p>
                                                        
                                                    <p>Terminal Illness • Certified by a physician as having an illness or physical condition which is reasonably expected to result in death within 12 months of diagnosis. •	 Can accelerate up to 95% of the eligible death benefit at the time of terminal illness claim to a maximum of $500,000.</p>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 pb-2" style="display: flex; justify-content: center; align-items: center; margin-top: 40px;">
                            
                            <button @click="close()" type="submit" class="btn btn-primary btn-loading">
                                
                                <span>Close</span>
                                
                            </button>
                        </div>
                      
                        
                       
                        
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </div>
</template>
<script>

export default {
    name: "living-benefits",
   
    
    data() {
        return {
            slot: '',
            loading: false,
            form: {
                name: '',
            },
        }
    },
    methods: {
        close() {
            //$(this.$parent.$refs.llp.$el).modal('hide');
            this.$parent.closeLivingBenefits();
        }
    }
   
   
    
}
</script>