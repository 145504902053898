<template>
  <div>
    <header v-if="$route.name == 'Home' || $route.name == 'Quote' || $route.name == 'Overview' || $route.name == 'Benefits' || $route.name == 'HowToApply'  || $route.name == 'About' || $route.name == 'Contact' || $route.name == 'Ticket' || $route.name == 'Login' || $route.name == 'Login2' || $route.name == 'Register' || $route.name == 'RegisterAll'" 
    :class="{'header-transparent' : this.$route.name != 'HowToApply'}" id=header-main>
            <nav class="navbar navbar-main navbar-expand-lg shadow" id=navbar-main
                :class="{
                    'navbar-light': this.$route.name == 'How ToApply' || this.$route.name == 'Quote' || this.$route.name == 'Ticket' || this.$route.name == 'Register' || this.$route.name == 'RegisterAll',
                    
                    'navbar-dark' : this.$route.name !== 'HowToApply' && this.$route.name !== 'Quote' && this.$route.name !== 'Ticket' && this.$route.name !== 'Register' && this.$route.name !== 'RegisterAll',
                    }"
                :style="styleObject"
                
            >
                <div class=container>
                <a v-if="this.$route.name == 'HowToApply'" class=navbar-brand href="https://visionarylifeinsurance.com">
                    <h2 class="display-6 my-4" style="font-weight:100">Visionary Inc</h2>
                </a>
                <a v-else-if="this.$route.name == 'Quote'" class=navbar-brand href="https://visionarylifeinsurance.com">
                    <h2 class="display-6 my-4" style="font-weight:100">Visionary Inc</h2>
                </a>
                <a v-else-if="this.$route.name == 'Ticket'" class=navbar-brand href="https://visionarylifeinsurance.com">
                    <h2 class="display-6 my-4" style="font-weight:100">Visionary Inc</h2>
                </a>
                <a v-else-if="this.$route.name == 'Register'" class=navbar-brand href="https://visionarylifeinsurance.com">
                    <h2 class="display-6 my-4" style="font-weight:100">Visionary Inc</h2>
                </a>
                <a v-else-if="this.$route.name == 'RegisterAll'" class=navbar-brand href="https://visionarylifeinsurance.com">
                    <h2 class="display-6 my-4" style="font-weight:100">Visionary Inc</h2>
                </a>
                <a v-else class=navbar-brand href="https://visionarylifeinsurance.com">
                    <h2 class="display-6 text-white my-4" style="font-weight:100">Visionary Inc</h2>
                </a>
                <button class="navbar-toggler" style="color:black;" type=button data-toggle=collapse data-target=#navbar-main-collapse aria-controls=navbar-main-collapse aria-expanded=false aria-label="Toggle navigation">
                    <i class="uil uil-bars" :class="{
                        'togglerLight' : this.$route.name !== 'HowToApply' && this.$route.name !== 'Quote' && this.$route.name !== 'Ticket' && this.$route.name !== 'Register' && this.$route.name !== 'RegisterAll',
                        'togglerDark' :  this.$route.name == 'How To Apply' || this.$route.name == 'Quote' || this.$route.name == 'Ticket' || this.$route.name == 'Register' || this.$route.name == 'RegisterAll',
                        }"></i>
                </button>
                <div class="collapse navbar-collapse navbar-collapse-overlay" id=navbar-main-collapse>
                    <div class=position-relative>
                        <button class=navbar-toggler style="color:black;" type=button data-toggle=collapse data-target=#navbar-main-collapse aria-controls=navbar-main-collapse aria-expanded=false aria-label="Toggle navigation">
                            <i class="uil uil-multiply" data-feather=x></i>
                         
                        </button>
                    </div>
                    <ul class="navbar-nav ml-lg-auto">
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <a class=nav-link href="https://visionarylifeinsurance.com" :class="{'navlink-black' : this.$route.name == 'HowToApply'}">Home</a>
                        </li>
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link class=nav-link :to="{name: 'Overview'}" :class="{'navlink-black' : this.$route.name == 'HowToApply'}">Overview</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link :class="{'navlink-black' : this.$route.name == 'HowToApply'}" class=nav-link :to="{name:'Benefits'}">Benefits</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link :class="{'navlink-black' : this.$route.name == 'HowToApply'}" class=nav-link :to="{name: 'HowToApply'}">How to Apply</router-link>
                        </li>
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link :class="{'navlink-black' : this.$route.name == 'About'}" class=nav-link :to="{name:'About'}">About</router-link>
                        </li>
                        
                        <li class="nav-item nav-item-spaced d-lg-block">
                            <router-link :class="{'navlink-black' : this.$route.name == 'HowToApply'}" class=nav-link :to="{name: 'Contact'}">Contact Us</router-link>
                        </li>
                        
                        
                       
                    </ul>
                    <ul class="navbar-nav align-items-lg-center d-lg-flex ml-lg-auto d-sm-none d-sm-none2 d-xs-none">
                        <li class=nav-item>
                            
                        </li>
                        <li class=nav-item>
                            
                        </li>
                        
                        
                        
                        
                    </ul>
                    
            </div>
        </div>
    </nav>
   </header>
    <router-view/>
    <footer v-if="this.$route.name !== 'Login' && this.$route.name !== 'Start' && this.$route.name !== 'A' && this.$route.name !== 'B'" class=position-relative id=footer-main>
    <div class="footer pt-lg-7 footer-dark bg-dark">
        <div class="shape-container shape-line shape-position-top shape-orientation-inverse">
            <svg width=2560px height=100px xmlns=http://www.w3.org/2000/svg xmlns:xlink=http://www.w3.org/1999/xlink preserveAspectRatio=none x=0px y=0px viewBox="0 0 2560 100" style="enable-background:new 0 0 2560 100" xml:space=preserve class=""><polygon points="2560 0 2560 100 0 100"></polygon>
            </svg>
        </div>
        
        <div class="container pt-4">
            <div class="row justify-content-center">
                <div class=col-lg-12><div class="row align-items-center">
                    <div class=col-lg-7><h3 class="text-secondary mb-2">Easy to Qualify <strong>Life Insurance</strong> for Diabetics</h3>
                        <p class="lead mb-0 text-white opacity-8">Individual Policy</p>
                    </div>
                    <div class="col-lg-5 text-lg-right mt-4 mt-lg-0">
                       
                        
                    </div>
                </div>
            </div>
        </div>
        <hr class="divider divider-fade divider-dark my-5">
        <div class="row justify-content-center">
            <div class="col-lg-10 mb-5 mb-lg-0">
                
                <p class="mt-4 text-sm opacity-8 pr-lg-4">
                </p>
                <p>
                    This is an individual Term Life Plan that has been designed to meet the needs of individuals with Type 1 or Type 2 Diabetes. THERE IS NO MEDICAL Exam required. To apply, there is a short telephone call with one of our Representatives. No Agent will visit unless you want to meet with one. Once approved, coverage starts immediately on the date you request.  The plan has multiple options that can fit your budget.  This plan is available with additional Benefits at NO ADDITIONAL cost to you.  Those benefits are Chronic Illness; very much like Long Term Care. Critical Illness; in the event of a Heart Attack, Stroke or Cancer you can access up to 95% of the Death Benefit while you are alive.  Helps pay for expenses while you recover. Many additional free benefits are available to policyholders.  This plan is available in every state except in NEW YORK.
                </p>
                
        </div>
        
        
        
    </div>
    <hr class="divider divider-fade divider-dark my-4">
    <div class="row align-items-center justify-content-md-between pb-4">
        <div class=col-md-6>
            <div class="copyright text-sm font-weight-bold text-center text-md-left">&copy; 2022 
                <a href=https://visionarylifeinsurance.com class=font-weight-bold target=_blank>Visionary Inc</a>. All rights reserved
            </div>
        </div>
        <div class=col-md-6>
            <ul class="nav justify-content-center justify-content-md-end mt-3 mt-md-0">
                <li class=nav-item>
                    <a class=nav-link href=#>Terms</a>
                </li>
                <li class=nav-item>
                    <a class=nav-link href=#>Privacy</a>
                </li>
                <li class=nav-item>
                    <a class=nav-link href=#>Cookies</a>
                </li>
            </ul>
        </div>
    </div>
</div>
</div>
</footer>
  </div>
</template>
<script>
import axios from 'axios';

axios.defaults.baseURL = 'https://vapi.visionarylifeinsurance.com';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('access_token');
//axios.defaults.baseURL = 'http://vapi.test.life:8000';

export default {
    data() {
        return {
            outLoading: false,
        }
    },
    watch: {
        '$route' () {
            $('#navbar-main-collapse').collapse('hide');
            window.scrollTo(0,0);
        }
    },
    computed: {
        styleObject() {
            if(this.$route.name == 'HowToApply') {
                return {background: 'white'};
            }
        },
    },
    methods: {
        
        logStateOut() {
            this.outLoading = true;
            this.$store.dispatch('destroyToken').then(resp => {
                console.log(resp.data);
                this.$router.push({name: 'Home'})
                this.outLoading = false;
            }).then(resp => { this.$router.push({name: 'Home'}) }).catch(err => { this.outLoading = false; this.$router.push({name: 'Home'}) });
        },
    }
}
</script>
<style>
 .navlink-black {
     color:black;
 }

 .togglerLight {
     color:rgba(255,255,255,0.8);font-size:1.45rem;
 }
  .togglerDark {
     color:rgba(0,0,0,0.8);font-size:1.45rem;
 }
 @media (max-width:576px){
    .d-sm-none2{display:none!important}
 }
</style>
