<template>
    <div class="card rounded-bottom-right">
        <!-- First Card Body -->
        <div v-if="!quoteSubmitted && $store.state.policy.quote" class="card-body">
            <div class="d-flex align-items-center mb-4">
                <h3 class="display-5 text-dark my-2 text-highlight-warning" style="margin: 0 auto;">Get a Free Life Quote</h3>
            </div>
            <div class="row justify-content-center list-group-flush mb-4">
                <div class="col-12">
                    <div id="form-contact">
                        
                        <div class="form-group">
                            
                            <select v-model="$store.state.dPolicy.amount" class="form-control custom-select-lg">
                                <option selected>Amount</option>
                                <option value="400">$400,000</option>
                                <option v-for="i in 14" :key="i" :value="400 - (i * 25)">${{ 400 - (i * 25) }},000</option>
                                </select>
                        </div>
                        <div class="form-group">
                            
                            <select v-model="$store.state.dPolicy.state" class="form-control custom-select-lg">
                                <option value="">State</option>
                                <option value="AK">Alaska</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                
                                
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>     
                                
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                
                                
                                
                                
                                <option value="MN">Minnesota</option>
                                
                                
                                <option value="NV">Nevada</option>
                                
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NY">New York</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                
                                
                                
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                
                                <option value="WI">Wisconsin</option>
                                

                            </select>
                        </div>
                        <div class="form-group">
                        
                            <select v-model="$store.state.dPolicy.ss" class="form-control custom-select-lg">
                                <option selected>Smoking Status</option>
                                <option value="ns">No</option>
                                <option value="ss">Yes</option>                                          
                                </select>
                        </div>
                        <div class="form-group">
                            <select v-model="$store.state.dPolicy.gender" class="form-control custom-select-lg">
                                <option selected>Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                </select>
                        </div>
                        
                        <div class="form-group">
                            
                            <div class="row">
                                <div class="col-4" style="padding-right:2px;">
                                    <select v-model="$store.state.dPolicy.month" class="form-control custom-select-lg">
                                        <option selected>Month</option>
                                        <option value="01">01 - January</option>
                                        <option value="02">02 - February</option>
                                        <option value="03">03 - March</option>
                                        <option value="04">04 - April</option>
                                        <option value="05">05 - May</option>
                                        <option value="06">06 - June</option>
                                        <option value="07">07 - July</option>
                                        <option value="08">08 - August</option>
                                        <option value="09">09 - September</option>
                                        <option value="10">10 - October</option>
                                        <option value="11">11 - November</option>
                                        <option value="12">12 - December</option>
                                    </select> 
                                </div>
                                <div class="col-4" style="padding-right:2px;padding-left:2px;">
                                    
                                    <select v-model="$store.state.dPolicy.day" class="form-control custom-select-lg">
                                        <option selected>Day</option>
                                        <option v-for="i in 31" :key="i" :value="i">{{i}}</option>
                                        
                                    </select> 
                                </div>
                                <div class="col-4" style="padding-left:2px;">
                                    <select v-model="$store.state.dPolicy.year" class="form-control custom-select-lg" >
                                        <option selected>Year</option>
                                        <option v-for="i in 100" :key="i" :value="todayYear-i">{{ todayYear-i }}</option>
                                    </select> 
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="reset" class="btn-reset d-none">

                            </button> 
                            <button type="submit" @click="submitQuote2" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                <span v-if="loading == false">Get your Estimate</span>
                                <span v-else>
                                    <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                </span>
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div v-if="!quoteSubmitted && !$store.state.policy.quote" class="card-body">
            <div class="d-flex align-items-center mb-4">
                <h3 class="display-5 text-dark my-2 text-highlight-warning" style="margin: 0 auto;">Get a Free Life Quote</h3>
            </div>
            <div class="row justify-content-center list-group-flush mb-4">
                <div class="col-12">
                    <div id="form-contact">
                        
                        <div class="form-group">
                            
                            <select @change="amountChange" class="form-control custom-select-lg">
                                <option selected>Amount</option>
                                <option value="400">$400,000</option>
                                <option v-for="i in 14" :key="i" :value="400 - (i * 25)">${{ 400 - (i * 25) }},000</option>
                                </select>
                        </div>
                        <div class="form-group">
                            
                            <select @change="stateChange" class="form-control custom-select-lg" v-model="form.state">
                                <option value="">State</option>
                                <option value="AL">Alabama</option>
                                <option value="AZ">Arizona</option>
                                <option value="AR">Arkansas</option>
                                <option value="CA">California</option>
                                <option value="CO">Colorado</option>
                                <option value="CT">Connecticut</option>
                                <option value="DE">Delaware</option>
                                <option value="FL">Florida</option>
                                <option value="GA">Georgia</option>     
                                <option value="ID">Idaho</option>
                                <option value="IL">Illinois</option>
                                <option value="IN">Indiana</option>
                                <option value="IA">Iowa</option>
                                <option value="KS">Kansas</option>
                                <option value="KY">Kentucky</option>
                                <option value="LA">Louisiana</option>
                                <option value="ME">Maine</option>
                                <option value="MD">Maryland</option>
                                <option value="MA">Massachusetts</option>
                                <option value="MI">Michigan</option>
                                <option value="MN">Minnesota</option>
                                <option value="MS">Mississippi</option>
                                <option value="MO">Missouri</option>
                                <option value="MT">Montana</option>
                                <option value="NE">Nebraska</option>
                                <option value="NV">Nevada</option>
                                <option value="NH">New Hampshire</option>
                                <option value="NJ">New Jersey</option>
                                <option value="NM">New Mexico</option>
                                <option value="NC">North Carolina</option>
                                <option value="ND">North Dakota</option>
                                <option value="OH">Ohio</option>
                                <option value="OK">Oklahoma</option>
                                <option value="OR">Oregon</option>
                                <option value="PA">Pennsylvania</option>
                                <option value="RI">Rhode Island</option>
                                <option value="SC">South Carolina</option>
                                <option value="SD">South Dakota</option>
                                <option value="TN">Tennessee</option>
                                <option value="TX">Texas</option>
                                <option value="UT">Utah</option>
                                <option value="VT">Vermont</option>
                                <option value="VA">Virginia</option>
                                <option value="WA">Washington</option>
                                <option value="WV">West Virginia</option>
                                <option value="WI">Wisconsin</option>
                                <option value="WY">Wyoming</option>

                            </select>
                        </div>
                        <div class="form-group">
                        
                            <select @change="ssChange" class="form-control custom-select-lg">
                                <option selected>Smoking Status</option>
                                <option value="ns">No</option>
                                <option value="ss">Yes</option>                                          
                                </select>
                        </div>
                        <div class="form-group">
                            <select @change="genderChange" class="form-control custom-select-lg">
                                <option selected>Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                </select>
                        </div>
                        
                        <div class="form-group">
                            
                            <div class="row">
                                <div class="col-4" style="padding-right:2px;">
                                    <select @change="changeMonth" class="form-control custom-select-lg">
                                        <option selected>Month</option>
                                        <option value="01">01 - January</option>
                                        <option value="02">02 - February</option>
                                        <option value="03">03 - March</option>
                                        <option value="04">04 - April</option>
                                        <option value="05">05 - May</option>
                                        <option value="06">06 - June</option>
                                        <option value="07">07 - July</option>
                                        <option value="08">08 - August</option>
                                        <option value="09">09 - September</option>
                                        <option value="10">10 - October</option>
                                        <option value="11">11 - November</option>
                                        <option value="12">12 - December</option>
                                    </select> 
                                </div>
                                <div class="col-4" style="padding-right:2px;padding-left:2px;">
                                    
                                    <select @change="changeDay" class="form-control custom-select-lg">
                                        <option selected>Day</option>
                                        <option v-for="i in 31" :key="i" :value="i">{{i}}</option>
                                        
                                    </select> 
                                </div>
                                <div class="col-4" style="padding-left:2px;">
                                    <select @change="changeYear" class="form-control custom-select-lg" >
                                        <option selected>Year</option>
                                        <option v-for="i in 100" :key="i" :value="todayYear-i">{{ todayYear-i }}</option>
                                    </select> 
                                </div>
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="reset" class="btn-reset d-none">

                            </button> 
                            <button type="submit" @click="submitQuote" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                <span v-if="loading == false">Get your Estimate</span>
                                <span v-else>
                                    <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                </span>
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </div>

        <!-- Second Card Body --> 
        <div v-if="quoteSubmitted && preLead" class="card-body">
            <div class="d-flex align-items-center mb-4">
                <h3 class="display-5 text-dark my-2 text-highlight-warning" style="margin: 0 auto;">Get a Free Life Quote</h3>
            </div>
            <div class="row justify-content-center list-group-flush mb-4">
                <div class="col-12">
                    <div class="card-header py-4 border-0 delimiter-bottom text-center" style="margin-top:-10px;">
                        <div v-if="!loadingEdit" class="h1 text-center mb-0">
                            $<span class="price font-weight-bolder">{{ $store.state.policy.quote }}

                            </span>
                            
                        </div>
                        <div v-else class="mb-4">
                            <span>
                                    <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                </span>
                        </div>
                        <span class="h6 text-center">Monthly Price</span>
                    </div>
                    <div class="card-body">
                       
                            
                        
                        
                        <ul class="list-unstyled text-sm mt-2 mb-2">
                            
                            <div class="form-group">
                                <div class="row justify-content-between">
                                    <div class="col-auto"><span class="h6 text-center">Coverage Amount</span></div>
                                    <div class="col-auto" style="display:flex;justify-content:end;">
                                        <button @click="editValue" type="button" class="btn btn-xs btn-primary btn-icon rounded-pill"><span class="btn-inner--icon"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit-2"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg></span> <span class="btn-inner--text">Edit</span></button>
                                    </div>
                                </div>
                                
                                <div class="h4 text-center mb-0 mt-3">
                                    $<span class="price font-weight-bolder">{{  coverageValue }},000</span>
                                </div>
                                
                            </div>
                            <input type="range" class="form-range" :min="minCoverageRange" :max="maxCoverageRange" step="25" :value="coverageValue" @input="showValue" @change="showValueChange" id="customRange3" style="width:100%;">
                            
                            
                            <div class="form-group mt-2">
                                <div class="row justify-content-between">
                                    <div class="col-auto"><span class="h6 text-center">Term Length</span></div>
                                    <div class="col-auto" style="display:flex;justify-content:end;">
                                        
                                    </div>
                                </div>
                                
                                <div class="h4 text-center mb-0 mt-3">
                                    <span class="price font-weight-bolder">{{  coverageTerm }}</span> Years
                                </div>
                                
                            </div>
                            <input type="range" class="form-range" :min="minTermRange" :max="maxTermRange" step="5" :value="coverageTerm" @input="showTerm" @change="showValueChange" id="customRange3" style="width:100%;">
                            
                        </ul>
                        
                    </div>
                    <div id="form-contact">
                        
                        
                        
                        
                        
                        
                        <div class="text-center">
                            <button type="reset" class="btn-reset d-none">

                            </button> 
                            <button @click="$router.push({name: 'Start'})" type="submit" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                <span>Get Started</span>
                                
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
            <!-- Pre Quote -->
        <div v-if="quoteSubmitted && !preLead" class="card-body">
            <div class="d-flex align-items-center mb-4">
                <h3 class="display-5 text-dark my-2 text-highlight-warning" style="margin: 0 auto;">Get a Free Life Quote</h3>
            </div>
            <div class="row justify-content-center list-group-flush mb-4">
                <div class="col-12">
                    <div class="card-header py-4 border-0 delimiter-bottom text-center" style="margin-top:-10px;">
                        <div v-if="!loadingEdit" class="h4 text-center mb-0">
                            <span class="price font-weight-bolder">Recieve your instant quote 

                            </span>
                            
                        </div>
                        <div v-else class="mb-4">
                            <span>
                                    <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                </span>
                        </div>
                        
                    </div>
                    <div class="card-body">
                       
                            
                        
                        
                        <ul class="list-unstyled text-sm mt-2 mb-2">
                            
                            <div class="form-group">
                                <label class="form-control-label">Name</label>
                                <div class="input-group">
                                    
                                    <input type="text" v-model="$store.state.directForm.name" class="form-control" id="input-email" placeholder="James Wallace">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-control-label">Email</label>
                                <div class="input-group">
                                    
                                    <input type="email" v-model="$store.state.directForm.email" @input="validateEmail" class="form-control" id="input-email" placeholder="name@example.com">
                                </div>
                            </div>
                            
                            
                            <div class="form-group">
                                <label class="form-control-label">Phone (optional)</label>
                                <div class="input-group">
                                    
                                    <input type="text" v-model="$store.state.directForm.phone" @input="acceptNumber" class="form-control" id="input-email" placeholder="(866) 458-9824">
                                </div>
                            </div>
                            
                            
                        </ul>
                        
                    </div>
                    <div id="form-contact">
                        
                        
                        
                        
                        
                        
                        <div class="text-center">
                            <button type="reset" class="btn-reset d-none">

                            </button> 
                            <button v-if="!pLoad" @click="showQuote()" type="submit" class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                <span>View Quote</span>
                                
                            </button>
                            <button v-else class="btn btn-block btn-lg btn-primary mt-4 mb-2">
                                <span>
                                    <div class="spinner-border" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                </span>
                            </button>
                            
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
export default {
    name: "forest-quoter",
    data() {
      return {
          loading: false,
          loadingEdit: false,
          todayYear: new Date().getFullYear() - 18,
          preLead: false,
          quoteSubmitted: false,
          policy: "",
          form: {
              amount: '',
              state: '',
              ss: '',
              gender: '',
              dob: '',
              age: '',
              term: 20,
          },
          prospect: {
              name: '',
              email: '',
              phone: '',
          },
          pLoad: false,
          selectedDay: '',
          selectedMonth: '',
          selectedYear: '',
          tempShowCard: false,
          coverageValue: 75,
          coverageTerm: 15,
          minCoverageRange: 50,
          maxCoverageRange: 400,
          minTermRange: 10,
          maxTermRange: 30,
          emailValid: '',
      }
  },
  mounted() {
      if(this.$store.state.dPolicy.year) {
          this.form = this.$store.state.dPolicy;
      }
  },
  created() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.$store.state.directForm.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
  },
  methods: {
      submitQuote() {
          console.log('submitting quote - ' + this.form.term);
          console.log(this.form);
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.selectedDay != '' && this.selectedMonth != '' && this.selectedYear != '') {
              this.loading = true;
              
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
                this.coverageTerm = this.form.term;
              this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              console.log("Form term - " + this.form.term);
            this.form.age = moment(this.form.dob, "MM/DD/YYYY").fromNow().split(" ")[0];
            console.log("Age: " + this.form.age);
            if(this.form.gender == "Male" && this.form.ss == "ns") {
                console.log("inside male ns")
                if(this.form.term == 10 && (this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 22');
                    this.minTermRange = 15;
                } 
                if((this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 22');
                    this.minTermRange = 15;
                }
                if(this.form.term == 20 && this.form.age >= 71) {
                    console.log('client is older than 65 for 20 year policy 10');
                    this.form.term = 10;
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 71) {
                    console.log('client is older than 71'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 for 20 year policy 15');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 within index ' + this.form.term); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55 for 25 year policy');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50 for 20 year policy');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50'); 
                    this.maxTermRange = 25;
                }
            } else if(this.form.gender == "Male" && this.form.ss == "ss") {
                console.log("inside male ss");
                if(this.form.term == 20 && this.form.age >= 61) {
                    console.log('client is older than 61 for 20 year policy 10');
                    this.form.term = 15;
                    this.maxTermRange = 15;
                    this.reSubmit();
                }
                if(this.form.age > 71) {
                    console.log('client is older than 71 ss'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss'); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 50 && this.form.age < 60) {
                    console.log('client is older than 55 ss');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 50 && this.form.age < 60) {
                    console.log('client is older than 50'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 45 && this.form.age < 50) {
                    console.log('client is older than 45');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.form.age > 45 && this.form.age < 50) {
                    console.log('client is older than 45'); 
                    this.maxTermRange = 25;
                }
            } else if(this.form.gender == "Female" && this.form.ss == "ns") {
                console.log("inside female ns");
                if(this.form.term == 10 && (this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22 || this.form.age == 23)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                    this.form.term = 15;
                    
                    this.reSubmit();
                } 
                if((this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22 || this.form.age == 23)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                }
                if(this.form.term >= 15 && this.form.age >= 71) {
                    console.log('client is older than 72');
                    this.form.term = 10;
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 71) {
                    console.log('client is older than 71'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 for 20 year policy 15');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 within index ' + this.form.term); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55 for 25 year policy');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50 for 20 year policy');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50'); 
                    this.maxTermRange = 25;
                }
            } else if(this.form.gender == "Female" && this.form.ss == "ss") {
                console.log("inside female ss");
                if(this.form.term == 10 && (this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                    this.form.term = 15;
                    
                    this.reSubmit();
                } 
                if((this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                }
                
                if(this.form.age > 71) {
                    console.log('client is older than 71 ss'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss'); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 55 && this.form.age < 60) {
                    console.log('client is older than 55 ss');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reSubmit();
                }
                if(this.form.age > 55 && this.form.age < 60) {
                    console.log('client is older than 55'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 55');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reSubmit();
                }
                if(this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 45'); 
                    this.maxTermRange = 25;
                }
            }

            
            

              axios.post("/api/fetchforestquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = resp.data.quote;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                policy.term = this.form.term;
                this.coverageValue = this.form.amount;
                
                    // do some logic for age limitations
                    

                    

                    

                   


                    

                
                // add to local policy for home quoter
                console.log(this.form);
                console.log("beofre form next dPolicy");
                console.log(this.$store.state.dPolicy);
                this.$store.state.dPolicy = policy;
                this.$store.state.dPolicy.month = policy.dob.split('/')[0];
                this.$store.state.dPolicy.day = policy.dob.split('/')[1];
                this.$store.state.dPolicy.year = policy.dob.split('/')[2];
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.quoteSubmitted = true;
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.selectedDay == '') {
              this.$toasted.error('Select Day');
          } else if(this.selectedMonth == '') {
              this.$toasted.error('Select Month');
          } else if(this.selectedYear == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      submitQuoteAlt() {
          console.log('submitting alt - ' + this.form.term);
          console.log(this.form);
          
          let policy = {};
         
              this.loading = true;
              
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
                this.coverageTerm = this.form.term;
              this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              console.log("Form term - " + this.form.term);
              axios.post("/api/fetchaltforestquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = resp.data.quote;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                policy.term = this.form.term;
                this.coverageValue = this.form.amount;
                
                    

                    



                    

                
                // add to local policy for home quoter
                this.$store.state.dPolicy = policy;
                this.$store.state.dPolicy.month = policy.dob.split('/')[0];
                this.$store.state.dPolicy.day = policy.dob.split('/')[1];
                this.$store.state.dPolicy.year = policy.dob.split('/')[2];
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.quoteSubmitted = true;
            });
          
      },
      submitQuote2() {
          console.log('submitting quote - dpolicy below');
          console.log(this.$store.state.dPolicy);
          this.form = this.$store.state.dPolicy;
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.form.day != '' && this.form.month != '' && this.form.year != '') {
              this.loading = true;
              
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
                this.coverageTerm = this.form.term;
              this.form.dob = this.$store.state.dPolicy.month + '/' + this.$store.state.dPolicy.day + '/' + this.$store.state.dPolicy.year;
              console.log("Form term - " + this.form.term);
            this.form.age = moment(this.form.dob, "MM/DD/YYYY").fromNow().split(" ")[0];
            console.log("Age: " + this.form.age);
            if(this.form.gender == "Male" && this.form.ss == "ns") {
                console.log("inside male ns")
                if(this.form.term == 10 && (this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 22');
                    this.minTermRange = 15;
                } 
                if((this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 22');
                    this.minTermRange = 15;
                }
                if(this.form.term == 20 && this.form.age >= 71) {
                    console.log('client is older than 65 for 20 year policy 10');
                    this.form.term = 10;
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 71) {
                    console.log('client is older than 71'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 for 20 year policy 15');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 within index ' + this.form.term); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55 for 25 year policy');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50 for 20 year policy');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reAltSubmit();
                }
                if(this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50'); 
                    this.maxTermRange = 25;
                }
            } else if(this.form.gender == "Male" && this.form.ss == "ss") {
                console.log("inside male ss");
                if(this.form.term == 20 && this.form.age >= 61) {
                    console.log('client is older than 61 for 20 year policy 10');
                    this.form.term = 15;
                    this.maxTermRange = 15;
                    this.reAltSubmit();
                }
                if(this.form.age > 71) {
                    console.log('client is older than 71 ss'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss'); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 50 && this.form.age < 60) {
                    console.log('client is older than 55 ss');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 50 && this.form.age < 60) {
                    console.log('client is older than 50'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 45 && this.form.age < 50) {
                    console.log('client is older than 45');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reAltSubmit();
                }
                if(this.form.age > 45 && this.form.age < 50) {
                    console.log('client is older than 45'); 
                    this.maxTermRange = 25;
                }
            } else if(this.form.gender == "Female" && this.form.ss == "ns") {
                console.log("inside female ns");
                if(this.form.term == 10 && (this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22 || this.form.age == 23)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                    this.form.term = 15;
                    
                    this.reAltSubmit();
                } 
                if((this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22 || this.form.age == 23)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                }
                if(this.form.term >= 15 && this.form.age >= 71) {
                    console.log('client is older than 72');
                    this.form.term = 10;
                    this.maxTermRange = 10;
                    this.reAltSubmit();
                }
                if(this.form.age > 71) {
                    console.log('client is older than 71'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 for 20 year policy 15');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 65 && this.form.age < 71) {
                    console.log('client is older than 65 within index ' + this.form.term); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55 for 25 year policy');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 55 && this.form.age < 65) {
                    console.log('client is older than 55'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50 for 20 year policy');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reAltSubmit();
                }
                if(this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 50'); 
                    this.maxTermRange = 25;
                }
            } else if(this.form.gender == "Female" && this.form.ss == "ss") {
                console.log("inside female ss");
                if(this.form.term == 10 && (this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                    this.form.term = 15;
                    
                    this.reAltSubmit();
                } 
                if((this.form.age == 18 || this.form.age == 19 || this.form.age == 20 || this.form.age == 21 || this.form.age == 22)) {
                    console.log('ages under 23');
                    this.minTermRange = 15;
                }
                
                if(this.form.age > 71) {
                    console.log('client is older than 71 ss'); 
                    this.maxTermRange = 10;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }
                if(this.form.term > 15 && this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss');
                    this.form.term = parseInt(15);
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 60 && this.form.age < 71) {
                    console.log('client is older than 60 ss'); 
                    this.maxTermRange = 15;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 20 && this.form.age > 55 && this.form.age < 60) {
                    console.log('client is older than 55 ss');
                    this.form.term = 20;
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                    this.reAltSubmit();
                }
                if(this.form.age > 55 && this.form.age < 60) {
                    console.log('client is older than 55'); 
                    this.maxTermRange = 20;
                    this.form.amount = 150;
                    this.maxCoverageRange = 150;
                }

                if(this.form.term > 25 && this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 55');
                    this.form.term = 25;
                    this.maxTermRange = 25;
                    this.reAltSubmit();
                }
                if(this.form.age > 50 && this.form.age < 55) {
                    console.log('client is older than 45'); 
                    this.maxTermRange = 25;
                }
            }

            
            

              axios.post("/api/fetchforestquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = resp.data.quote;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                policy.term = this.form.term;
                this.coverageValue = this.form.amount;
                
        
                this.$store.state.dPolicy = policy;
                this.$store.state.dPolicy.month = policy.dob.split('/')[0];
                this.$store.state.dPolicy.day = policy.dob.split('/')[1];
                this.$store.state.dPolicy.year = policy.dob.split('/')[2];
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.quoteSubmitted = true;
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.form.day == '') {
              this.$toasted.error('Select Day');
          } else if(this.form.month == '') {
              this.$toasted.error('Select Month');
          } else if(this.form.year == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      submitQuoteAlt2() {
          console.log('submitting alt 2 - ' + this.form.term);
          console.log(this.form);

          this.form = this.$store.state.dPolicy;
          
          let policy = {};
         
              this.loading = true;
              
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
                this.coverageTerm = this.form.term;
              this.form.dob = this.$store.state.dPolicy.month + '/' + this.$store.state.dPolicy.day + '/' + this.$store.state.dPolicy.year;
              console.log("Form term - " + this.form.term);
              axios.post("/api/fetchaltforestquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = resp.data.quote;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                
                policy.rstartdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                policy.term = this.form.term;
                this.coverageValue = this.form.amount;
                
                    

                    



                    

                
                // add to local policy for home quoter
                this.$store.state.dPolicy = policy;
                this.$store.state.dPolicy.month = policy.dob.split('/')[0];
                this.$store.state.dPolicy.day = policy.dob.split('/')[1];
                this.$store.state.dPolicy.year = policy.dob.split('/')[2];
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.quoteSubmitted = true;
            });
          
      },
      submitQuote2Old() {
          console.log('submitting quote');
          console.log(this.form);
          this.form = this.$store.state.dPolicy;
          
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.form.day != '' && this.form.month != '' && this.form.year != '') {
              this.loading = true;
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
              this.form.dob = this.form.month + '/' + this.form.day + '/' + this.form.year;
              axios.post("/api/fetchforestquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = parseInt(resp.data.quote) + 400;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                this.coverageValue = this.form.amount;
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.term = this.form.term;
                policy.gender = this.form.gender;
                policy.state = this.form.state;


                this.$store.state.dPolicy = policy;
                this.$store.state.dPolicy.month = policy.dob.split('/')[0];
                this.$store.state.dPolicy.day = policy.dob.split('/')[1];
                this.$store.state.dPolicy.year = policy.dob.split('/')[2];
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loading = false;
                this.quoteSubmitted = true;
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.form.day == '') {
              this.$toasted.error('Select Day');
          } else if(this.form.month == '') {
              this.$toasted.error('Select Month');
          } else if(this.form.year == '') {
              this.$toasted.error('Select Year');
          }
          
      },
      showQuote() {
          this.pLoad = true;
          this.prospect.name = this.$store.state.directForm.name;
          this.prospect.email = this.$store.state.directForm.email;
          this.prospect.phone = this.$store.state.directForm.phone;
        
          // assign 
          this.prospect.dob= this.$store.state.dPolicy.dob;
          this.prospect.amount = this.$store.state.dPolicy.amount;
          this.prospect.gender = this.$store.state.dPolicy.gender;
          this.prospect.quote = this.$store.state.dPolicy.quote;
          this.prospect.ss = this.$store.state.dPolicy.ss;
          this.prospect.term = this.$store.state.dPolicy.term;


          this.$store.state.directForm.first_name = this.prospect.name.split(' ')[0];
          if(this.prospect.name.split(' ')[1]) {
              this.$store.state.directForm.last_name = this.prospect.name.split(' ')[1];
          }
          if(this.$store.state.directForm.name != '' && this.$store.state.directForm.email != '' && this.emailValid == true) {
               axios.post('/api/submitquoteproxy', this.prospect).then(resp => {
                   console.log('recieved from prospect')
                  console.log(resp.data);
                  this.preLead = true;
                  this.pLoad = false;
              }).catch(resp2 => {
                  this.pLoad = false;
              });
          } else {
              this.pLoad = false;
              this.$toasted.error("Required");
          }
      },
      reSubmit() {
          
          console.log("resubmitting ");
          this.submitQuoteAlt();
      },
      reAltSubmit() {
          
          console.log("resubmitting ");
          this.submitQuoteAlt2();
      },
      validateEmail() {
            if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(this.$store.state.directForm.email)) {
                console.log('email looks alright');  
                this.emailValid = true;
            } else {
                console.log('Please enter a valid email address');
                this.emailValid = false;
            }
        },
      acceptNumber() {
            var x = this.$store.state.directForm.phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            this.$store.state.directForm.phone = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        },
      showValue(event) {
          console.log(event.target.value);
          this.coverageValue = event.target.value;
      },
      showTerm(event) {
          console.log(event.target.value);
          this.coverageTerm = event.target.value;
      },
      showValueChange(event){
          this.form = this.$store.state.dPolicy;
          this.form.amount = this.coverageValue;
          this.form.term = this.coverageTerm;
          this.form.dob = this.form.month + '/' + this.form.day + '/' + this.form.year;
          let policy = {};
          if(this.form.amount != '' && this.form.state != '' && this.form.ss != '' && this.form.gender != '' && this.form.day != '' && this.form.month != '' && this.form.year != '') {
              this.loadingEdit = true;
              this.$store.dispatch('clearShelf');
              this.$store.state.policySpouse = '';
                this.$store.state.rider1 = '';
                
              //this.form.dob = this.selectedMonth + '/' + this.selectedDay + '/' + this.selectedYear;
              axios.post("/api/fetchforestquote", this.form).then(resp => {
                console.log(resp.data.quote);
                console.log(resp);
                policy.quote = resp.data.quote;
                policy.ss = this.form.ss;
                policy.amount = this.form.amount;
                this.coverageValue = this.form.amount;
                policy.startdate = resp.data.policystart;
                policy.age = resp.data.diff;
                policy.dob = this.form.dob;
                policy.gender = this.form.gender;
                policy.state = this.form.state;
                policy.term = this.form.term;
                this.$store.state.dPolicy = policy;
                this.$store.state.dPolicy.month = policy.dob.split('/')[0];
                this.$store.state.dPolicy.day = policy.dob.split('/')[1];
                this.$store.state.dPolicy.year = policy.dob.split('/')[2];
                this.$store.commit('addPolicy', policy);
            }). then(resp2 => {
                this.loadingEdit = false;
                this.quoteSubmitted = true;
            });
          } else if(this.form.amount == '') {
              this.$toasted.error('Select Amount');
          } else if(this.form.state == '') {
              this.$toasted.error('Select State');
          } else if(this.form.ss == '') {
              this.$toasted.error('Select Smoking Status');
          } else if(this.form.gender == '') {
              this.$toasted.error('Select Gender');
          } else if(this.form.day == '') {
              this.$toasted.error('Select Day');
          } else if(this.form.month == '') {
              this.$toasted.error('Select Month');
          } else if(this.form.year == '') {
              this.$toasted.error('Select Year');
          }
      },
      editValue(){
          this.quoteSubmitted = false;
      },
      amountChange(event) {
          if(event.target.value !== "Amount") {
              this.form.amount = event.target.value;
              console.log('succesfully changed');
          } else {
              console.log("please select from the dropdown");
          }
      },
      stateChange(event) {
          if(event.target.value !== "") {
              this.form.state = event.target.value;
              console.log('succesfully changed states');
          } else {
              console.log("please select from the dropdown");
          }
      },
      ssChange(event) {
          if(event.target.value !== "Smoking Status") {
              this.form.ss = event.target.value;
              console.log('succesfully selected status');
          } else {
              console.log("please select from the dropdown");
          }
      },
      genderChange(event) {
          if(event.target.value !== "Gender") {
              this.form.gender = event.target.value;
              console.log('succesfully selected gender');
          } else {
              console.log("please select from the dropdown");
          }
      },
      changeDay(event) {
          if(event.target.value !== 'Day') {
              this.selectedDay = event.target.value;
              console.log(event.target.value);
          }
      },
      changeMonth(event) {
          if(event.target.value !== 'Month') {
              this.selectedMonth = event.target.value;
              console.log(event.target.value);
          }
      },
      changeYear(event) {
          if(event.target.value !== 'Year') {
              this.selectedYear = event.target.value;
              console.log(event.target.value);
          }
      }
  }
}
</script>